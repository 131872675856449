import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'

const CarouselGaleria = ( props ) => {
  
    const [index, setIndex] = useState(props.index);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
        
        return (

        <section>
            <Carousel activeIndex={index} className='carousel__box  animated fast fadeIn' indicators={ false } onSelect={ handleSelect } >
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/galeria/galeria-1.jpg" alt="Imagenes renderizadas de Mia Canning" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/galeria/galeria-2.jpg" alt="Imagenes renderizadas de Mia Canning" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/galeria/galeria-3.jpg" alt="Imagenes renderizadas de Mia Canning" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/galeria/galeria-4.jpg" alt="Imagenes renderizadas de Mia Canning" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/galeria/galeria-5.jpg" alt="Imagenes renderizadas de Mia Canning" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/galeria/galeria-6.jpg" alt="Imagenes renderizadas de Mia Canning" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/galeria/galeria-7.jpg" alt="Imagenes renderizadas de Mia Canning" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/galeria/galeria-8.jpg" alt="Imagenes renderizadas de Mia Canning" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/galeria/galeria-9.jpg" alt="Imagenes renderizadas de Mia Canning" loading="lazy" />  </Carousel.Item>
            </Carousel> 
        </section>

    )
}

export default CarouselGaleria