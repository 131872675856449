import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { scrollPosition } from '../Hooks/useScrollPosition.js'

import MiaCanning from '../Resources/mia-canning.svg'
import IconInstagram from '../Resources/icon-instagram.svg'
import IconContacto from '../Resources/icon-contact.svg'
import IconLocation from '../Resources/icon-location.svg'

const NavbarSite = () => {

  return (
    <Container className="row " style={{margin: "0 auto"}} >
      <div className="col-7 col-md-9 text-center">
        <Link to={'/'} style={{ cursor: 'pointer'}}>
          <img src={MiaCanning} alt="Mia Canning" className='navbar-img' />
         </Link>
      </div>
      <div className="col-5 col-md-3 navbarLayout">
        <Nav className="justify-content-end navbarLayout__items" activeKey="/home">
          <Nav.Item className=" navbarlinks__btn navbarlinks__btn--right ">
            <Link to={'/el-distrito'}  className='link__reset'> El Distrito </Link>
          </Nav.Item>
          <Nav.Item className=" navbarlinks__btn navbarlinks__btn--right ">
            <Link to={'/galeria'} className='link__reset'>Galeria  </Link>
          </Nav.Item>
          <Nav.Item className=" navbarlinks__btn navbarlinks__btn--right ">
            <Link to={'/contacto'} className='link__reset'>Contacto </Link>
          </Nav.Item>
        </Nav>
        <Nav className="justify-content-end navbarLayout__icons" activeKey="/home">
          <Nav.Item className=" navbarlinks__btn navbarlinks__btn--right ">
            <a href='https://www.instagram.com' target={'_blank'} rel='noreferrer' className='link__reset'>
              <img src={IconInstagram} alt="Instagram"  className='navbar__icon'/>
            </a>
          </Nav.Item>
          <Nav.Item className=" navbarlinks__btn navbarlinks__btn--right ">
            <Link to={'/contacto/'} className='link__reset'>
              <img src={IconContacto} alt="Contacto"  className='navbar__icon'/>
            </Link>
          </Nav.Item>
          <Nav.Item className=" navbarlinks__btn navbarlinks__btn--right">
            <a 
              href="https://www.google.com/maps/@-34.8797314,-58.5034385,3a,75y,48.43h,100.11t/data=!3m7!1e1!3m5!1slnVUmFORGB2cIe1JDzaafw!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DlnVUmFORGB2cIe1JDzaafw%26cb_client%3Dsearch.revgeo_and_fetch.gps%26w%3D96%26h%3D64%26yaw%3D54.166416%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192"
              target="_blank"
              rel="noreferrer"
            >
            <img src={IconLocation} alt="Ubicacion" className='navbar__icon'/>
            </a>
          </Nav.Item>
        </Nav>
      </div>
    </Container>
  )
}

export default NavbarSite