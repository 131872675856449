import React from 'react'
// import ReactPlayer from 'react-player';
import videoUrl from '../Resources/miacanning.mp4'
const VideoPlayer = () => {
  return (

     
    <div>
      <video 
        autoplay="autoplay"
        loop="loop" 
        muted 
        defaultMuted 
        playsinline  
        oncontextmenu="return false;"  
        preload="auto"
        controls
        className='videoPlayer'
      >
        <source src={videoUrl}  type="video/mp4"/>
          Tu navegador no es compatible con videos de HTML 5
      </video>
    </div>
  )
}

export default VideoPlayer