import React from 'react'
import { Container } from 'react-bootstrap'
import NavbarSite from './NavbarSite'
import IconMia from  '../Resources/mia-headers.svg'
import Triangulo from '../Resources/icono-triangulo.svg'
import Bullet from './Bullet'

const Ubicacion = () => {
  return (
    <Container className='p-0 mb-0' >
         <div><h1 className='navbarH1'>DISTRITO</h1></div>
        <NavbarSite/>
        <div className='containerHeaders distritoContainer'>
          <div className='row'>
            <div className='col-md-8 col-lg-9 col-xl-10'>
              <p className='distritoContainer__titulo'>
                <span className='distritoContainer__titulo--bold'>DISTRITO MIA CANNING</span><br />
               <span className='distritoContainer__titulo--light'> UNA UBICACION DE PRIVILEGIO</span>
              </p>
            </div>
            <div className='col-md-4 col-lg-3 col-xl-2 distritoContainer__mia'>
              <img src={IconMia} alt="" className='distritoContainer__mia--img' />
            </div>
          </div>
        </div>
      <div className='containerBackground containerBackground__ubicacion'>
        <div className='row'>
          <div className='col-12'>
            <div className='col-md-8 col-lg-9 col-xl-12 pt-5'>
                <p className='distritoContainer__titulo titulosInteranas'>
                    <span className='distritoContainer__titulo--bold distritoContainer__titulo--gris'>FÁCIL DE LLEGAR, RAPIDO Y CÓMODO</span>
                </p>
            </div>
            <div className='textosBloques'>
              <p>  <img src={Triangulo} alt="" className='textosBloques__icon' /> </p>
              <p className='textosBloques__txt'>
              <strong>A 45 minutos de C.A.B.A. a 20 del Aeropuerto Internacional de Ezeiza,</strong> sobre la Ruta 52 y Boulevard Dupuy, una de las  mejores zonas de Canning, frente a St. Thomas, a 200m del Colegio Grilli  y pegado al Golf Club Esperanza, hacen que<strong> Distrito Mia Canning,</strong> tenga un potencial inmejorable.
              </p>
            </div>
          </div>

          <div className='col-12 imagenContainer text-center'>
            <img src="images/ubicacion/ubicacion.jpg"         className='imagenRedondeada item__desktop' alt="Render aereo ubicacion de Mia Canning" />
            <img src="images/ubicacion/ubicacion-mobile.jpg"  className='imagenRedondeada item__mobile' alt="Render aereo ubicacion de Mia Canning" />
          </div>

          <div className='col-md-8 col-lg-9 col-xl-12'>
            <p className='distritoContainer__titulo titulosInteranas titulosInteranas__mobile titulosInteranas__ubicacion'>
                <span className='distritoContainer__titulo--bold distritoContainer__titulo--gris'>EN EL CORAZÓN DE CANNING</span>
            </p>
          </div>

          <div className='col-12 col-lg-6 col-md-6'>
            <ul className='titulosInteranas titulosInteranas__lista'>
              <li className='titulosInternas__listas--li'><Bullet numero={"1"}/> Au. Cañuelas Ezeiza</li>
              <li className='titulosInternas__listas--li'><Bullet numero={"2"}/> Aeropuerto Internacional de Ezeiza</li>
              <li className='titulosInternas__listas--li'><Bullet numero={"3"}/> COTO</li>
              <li className='titulosInternas__listas--li'><Bullet numero={"4"}/> Disco / Easy</li>
              <li className='titulosInternas__listas--li'><Bullet numero={"5"}/> Las Toscas Shopping</li>
              <li className='titulosInternas__listas--li'><Bullet numero={"6"}/> Rotonda de la Mujer</li>
            </ul>
          </div>
          
          <div className='col-12 col-lg-6 col-md-6'>
            <ul className='titulosInteranas titulosInteranas__lista'>
              <li className='titulosInternas__listas--li'><Bullet numero={"7"}/> Grilli Canning College</li>
              <li className='titulosInternas__listas--li'><Bullet numero={"8"}/> St. Thomas Country Club</li>
              <li className='titulosInternas__listas--li'><Bullet numero={"9"}/> Terralagos</li>
              <li className='titulosInternas__listas--li'><Bullet numero={"10"}/> Campo Azul Barrio cerrado</li>
              <li className='titulosInternas__listas--li'><Bullet numero={"11"}/> El Lauquén Club de Campo</li>
              <li className='titulosInternas__listas--li'><Bullet numero={"12"}/> La Providencia Resort & Country Club </li>
            </ul>
          </div>
         

        </div>
      </div> 
    </Container>
  )
}

export default Ubicacion