import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, HashRouter } from 'react-router-dom';

import Home from './Components/Home';
import Footer from './Components/Footer';
import Distrito from './Components/Distrito';
import CentroComercial from './Components/CentroComercial';
import Ubicacion from './Components/Ubicacion';
import MacroFrancciones from './Components/MacroFrancciones';
import Galeria from './Components/Galeria';
import Contacto from './Components/Contacto';

function App() {
  
  return (
      <div className="conatiner-fluid p-0">
        <HashRouter>
            <Routes>
              <Route exact path='/' element={<Home/>} />

              <Route exact path='/el-distrito/' element={<Distrito/>} />
              <Route exact path='/ubicacion/' element={ <Ubicacion />} />
              <Route exact path='/centro-comercial/' element={<CentroComercial/>} />
              <Route exact path='/macro-fracciones/' element={<MacroFrancciones/>} />
              <Route exact path='/galeria/' element={<Galeria/>} />
              <Route exact path='/contacto/' element={<Contacto/>} />
              
              <Route path="*" element={<h2 className='text-center' style={{ paddingTop: 180, paddingBottom: 180}} >404 - Página no encontrada.</h2>}/>
            </Routes>
          <Footer /> 
        </HashRouter>
      </div>
  );
}

export default App;
