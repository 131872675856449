import React from 'react'

const Bullet = ( { numero }) => {
  return (
    <div className='bullet__numero'>
        <p>{numero}</p>
    </div>
  )
}

export default Bullet