import React from 'react'
import { Container } from 'react-bootstrap'
import IconInstagram from '../Resources/icons-redes-ig.svg'
import IconLinkedin from  '../Resources/icons-redes-in.svg'
import IconFacebook from  '../Resources/icons-redes-fb.svg'
import ReneArq from  '../Resources/rene-arquitectura.svg'
import IconMia from  '../Resources/footer-icon-mia.svg'



const Footer = () => {
  return (
    <footer className='p-0 m-0' >
      <Container className='footerContainer footerContainer__box' >
        <div className="container text-center m-0 p-0 ">
          <div className="row footer__row--iconContainer">
           

            <div className='col-12 col-md-2 footer__items '>
              <img src={IconMia} alt=""  className='d-block w-75 footer__items--mia'/>
            </div>

            <div className='col-12 col-md-4 box footer__items'>
                <div>
                  <p className='footer__titulo footer__items--shadow'><strong>DISTRITO MIA CANNING</strong></p>
                  <p className='footer__texto footer__items--shadow'>
                    RUTA 52 Y DUPUY, CANNING<br/>
                    CP 1804, ESTEBAN ECHEVERRIA<br/>
                    BUENOS AIRES, ARGENTINA<br/>
                    WWW.MIACANNING.COM.AR<br/><br/>
                    4295-6364 / 6364 I 11 54877428<br />
                    {/* <a href='mailto:info™epicel.com.ar'>info@epicel.com.ar</a> */}
                  </p>
                </div>
               <div>
                {/* <img src={Line} className="d-block w-100" alt="" /> */}
              </div> 
            </div>

            <div className='col-12 col-md-5 box footer__items footer__item--mobile'>
              <div className='footer__items--50'> 
                <p className='footer__titulo text-left footer__items--shadow'><strong>COMERCIALIZA</strong></p>
                <a 
                  href='https://www.epicel.com.ar/'
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src="./images/epicel.png" alt="Epicel" style={{textAlign: 'left'}} className="d-block footer__items--comercializa "/>
                </a>
              </div>
              <div className='footer__items--30 footer__items--shadow'>
                <img src={ReneArq} alt="Reñe Arquitectura" className='footer__renie d-block w-100 text-center' />
              </div>
              <div className='footer__items footer__items--contRedes footer__items--20'>
                <div>
                  <span className='footer__items--redes '>
                    <a href="https://www.facebook.com"  target="_blank" rel="noreferrer" className='navbarlinks__btn navbarlinks__btn--mia footer__items--shadow'><img style={{height: '35px'}} src={IconFacebook} alt="Facebook" /></a>
                    <a href="https://www.instagram.com" target="_blank" rel="noreferrer" className='navbarlinks__btn navbarlinks__btn--mia footer__items--shadow'><img style={{height: '35px'}} src={IconInstagram} alt="Istagram" /></a>
                    <a href="https://www.linkedin.com"  target="_blank" rel="noreferrer" className='navbarlinks__btn navbarlinks__btn--mia footer__items--shadow'><img style={{height: '35px'}} src={IconLinkedin} alt="Linkedin" /></a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container text-center m-0 p-0 ">
          <div className='col-12 col-md-12 footer__socioBrand'>
              <a href='https://www.socioboutique.com.ar/'
                target="_blank"
                rel="noreferrer"
              >
                <p className='footer__socioBrand--text'>by <strong>Socio</strong> Branding <strong>Boutique</strong></p>
              </a> 
            </div>
          </div>

         
        </div> 
        
      </Container>
    </footer>
  )
}

export default Footer