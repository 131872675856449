import React, {useState} from 'react'
import { useForm } from 'react-hook-form'

import { Container } from 'react-bootstrap'
import NavbarSite from './NavbarSite'
import IconMia from  '../Resources/mia-headers.svg'
import Triangulo from '../Resources/icono-triangulo.svg'
import ButtonComponent from './ButtonComponent'

import emailjs from '@emailjs/browser';

const Contacto = () => {


    const [buttonSend, setButtonSend] =  useState(true)
    const [formSent, setFormSent] = useState(false)
    const [formSentNo, setFormSentNo] = useState(false)
    const [formSending, setFormSending] = useState(false)
    
   
    const {
        register, 
        resetField,
        handleSubmit, 
        formState: { errors }} = useForm({
        defaultValues:{
            userName: '',
            userTelephone: '',
            userEmail: '',
            userMessage: ''
        }
    });

    function sendEmail(data, e){

        console.log('sendEmail')
        e.preventDefault();
        setButtonSend(false);
        setFormSending(true)
        
        emailjs.sendForm('service_v2ugw0g', 'template_q9oh6ee', e.target, 'u46ocKQ3h_ngAAYuz').then(
            res => {
            
                resetField('userName'); resetField('userTelephone'); resetField('userEmail'); resetField('userMessage');
                console.log('CONTACTO FOOTER, ', res);
                    setFormSent(true)
                setFormSending(false)

                setTimeout( ()=>{
                    setFormSent(false);
                    setButtonSend(true);
                }, 3000)
                e.target.reset();
            }
        )
    }

    


  return (
    <Container className='p-0 mb-0' >
        {/* INICIA HEADER */}
        <div><h1 className='navbarH1'>DISTRITO</h1></div>
        <NavbarSite/>
        <div className='containerHeaders distritoContainer'>
          <div className='row'>
            <div className='col-md-8 col-lg-9 col-xl-10'>
              <p className='distritoContainer__titulo'>
                <span className='distritoContainer__titulo--bold'>DISTRITO MIA CANNING</span><br />
               <span className='distritoContainer__titulo--light'> CONTÁCTESE CON NOSOTROS.</span>
              </p>
            </div>
            <div className='col-md-4 col-lg-3 col-xl-2 distritoContainer__mia'>
              <img src={IconMia} alt="" className='distritoContainer__mia--img' />
            </div>
          </div>
        </div>
        {/* FIN HEADER  */}

      
        <div className='containerBackground'>
         
          <div className='row'>
            <div className='col-12'>
              <div className='textosBloques'>
                <p> <img src={Triangulo} alt="" className='textosBloques__icon' /> </p>
                <p className='textosBloques__txt'>
                Complete el formulario y nos contactaremos a la brevedad con usted, para contarle en detalle sobre este mega desarrollo inmobiliario.</p>
              </div>
            </div>

            <div className='col-12 imagenContainer text-center'>
              <img src="images/distrito/distrito-render-2.jpg" className='imagenRedondeada' alt="Render aereo del distrito Mia Canning" />
            </div>

            <div className='col-md-8 col-lg-9 col-xl-12'>
              <p className='distritoContainer__titulo titulosInteranas titulosInteranas__mobile titulosInteranas__ubicacion'>
                  <span className='distritoContainer__titulo--bold distritoContainer__titulo--gris'>CONTACTO</span>
              </p>
            </div>
          <div >
           
            <form className='row d-flex justify-content-center titulosInteranas__ubicacion ' onSubmit={handleSubmit(sendEmail)} >   
                
                <div className='col-md-12 formInputsBlock '>
                  {/* NOMBRE */}
                  <p className='titleInput'>Nombre y Apellido</p>
                    <input 
                      { ...register( 'userName', { 
                          required: 'El nombre solo puede contener letras y espacios.', 
                          pattern: { 
                              value: /[A-Za-z]{3}/, 
                              message: 'El nombre solo acepta letras y 3 como minimo.'
                          }
                      })}
                     className='formInput__box'  />
                  <p className='formInput__error'> {errors.userName?.message}</p>
                  {/* TELEFONO */}
                  <p className='titleInput'>Telefono</p>
                  <input 
                      type = 'number'
                      { ...register('userTelephone', { 
                      required: 'El télefono solo puede contener numeros, guiones y parentesis y no menos de 8 numeros.'
                      , minLength: { value: 8, message: 'El nuemero de ser de mīnimo de 8 caracteres'} })} 
                      className='formInput__box'  />
                  <p className='formInput__error'> {errors.userTelephone?.message}</p>
                  {/* EMAIL  */}
                  <p className='titleInput'>Email</p>   
                  <input 
                    {...register('userEmail', { 
                        required: 'El solo puede contener letras, numeros, puntos y guiones bajos', 
                        pattern: { 
                            value: /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]/ ,
                            error: 'error de mail'
                        }   
                    })} 
                    className='formInput__box'  />
                  <p className='formInput__error'> {errors.userEmail?.message}</p>
                  {/* MENSAJE  */}
                  <p className='titleInput'>Mensaje</p>
                  <textarea  { ...register('userMessage', { 
                          required: 'El mensaje debe tener mas de 30 caracteres', 
                          minLength: {
                              value: 30, 
                              message: 'El mensaje debe tener mas de 30 caracteres.'
                          } 
                      })} 
                      
                      className='formInput__box formInput__textarea' >
                  </textarea>
                  <p className='formInput__error'> {errors.userMessage?.message}</p>
                </div>
                { formSending && <div className='text-center formInput__sent' style={{height: "40px", marginTop: "20px", color: "white"}}> 
                    <i className="fas fa-spinner fa-spin fa-3x"></i></div>
                }
                <div className='col-md-10 text-center pt-5 pb-0 mb-3'>
                    { formSent   &&  <p className='formInput__sent'>Formulario enviado. Proximamente nos comunicaremos.<br /> Muchas Gracias</p>}
                    { formSentNo &&  <p className='formInput__sentNo'>Se ha producido un ERROR al enviar el formulario, por favor intentelo nuevamente en unos minutos.<br /> Muchas Gracias</p>}
                    { buttonSend && <ButtonComponent  text={'Enviar'} classButton={'button-rounded--outline'} type='submit' /> }
                </div>
            </form>
          
          </div>
          
          <div className='col-12  text-center'>
            <img src="images/galeria/galeria-7.jpg" className='d-block w-100' alt="Render aereo del distrito Mia Canning" />
          </div>
        </div>
      </div> 
    </Container>    
  )


}

export default Contacto