import React, { useState } from 'react'
import { Container, Modal } from 'react-bootstrap'
import NavbarSite from './NavbarSite'
import IconMia from  '../Resources/mia-headers.svg'
import CarouselGaleria from './CarouselGaleria'

const Galeria = () => {

     // --------- INICIO DE LOGICA DE CAROUSEL --------- //

     const [show, setShow] = useState(false);
     const [idImg, setIdImg] = useState();
 
     const handleClose = () => { setShow(false); }
     const handleShow = (idPic) => {
         setShow(true)
         setIdImg(idPic)
         console.log('setIdImg()')
 
     }
 
     // --------- FIN DE LOGICA DE CAROUSEL --------- //

  return (
    <Container className='p-0 mb-0' >
        <Modal show={show} size='lg' onHide={handleClose} ackdrop="static" keyboard={false} >
            <Modal.Header closeButton>
                <Modal.Title>Galeria de Mia Canning.</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <CarouselGaleria index={ idImg }/>
            </Modal.Body>

            <Modal.Footer className={'modal__desc'} >
                <small>Imágenes del proyecto.</small>
            </Modal.Footer>
        </Modal>

         <div><h1 className='navbarH1'>DISTRITO</h1></div>
        <NavbarSite/>
        <div className='containerHeaders distritoContainer'>
          <div className='row'>
            <div className='col-md-8 col-lg-9 col-xl-10'>
              <p className='distritoContainer__titulo'>
                <span className='distritoContainer__titulo--bold'>DISTRITO MIA CANNING</span><br />
               <span className='distritoContainer__titulo--light'>GALERIA DE IMAGENES</span>
              </p>
            </div>
            <div className='col-md-4 col-lg-3 col-xl-2 distritoContainer__mia'>
              <img src={IconMia} alt="" className='distritoContainer__mia--img' />
            </div>
          </div>
        </div>
      {/* <div className='containerBackground containerBackground__ubicacion'> */}
      <div className='containerBackground '>
        <div className='row gallery__container' >
           
            <div className="gallery__cont pt-5">
                <div className="column">
                    <img onClick={ () => handleShow(0) } src="images/galeria/galeria-1.jpg" className="d-block imagenRedondeadGaleria" alt="Galeria de Mia Canning" data-img-mostrar="1" />
                    <img onClick={ () => handleShow(1) } src="images/galeria/galeria-2.jpg" className="d-block imagenRedondeadGaleria" alt="Galeria de Mia Canning" data-img-mostrar="2" />
                    <img onClick={ () => handleShow(2) } src="images/galeria/galeria-3.jpg" className="d-block imagenRedondeadGaleria" alt="Galeria de Mia Canning" data-img-mostrar="3" />
                </div>
                <div className="column">
                    <img onClick={ () => handleShow(3) } src="images/galeria/galeria-4.jpg" className="d-block imagenRedondeadGaleria" alt="Galeria de Mia Canning" data-img-mostrar="4" />
                    <img onClick={ () => handleShow(4) } src="images/galeria/galeria-5.jpg" className="d-block imagenRedondeadGaleria" alt="Galeria de Mia Canning" data-img-mostrar="5" />
                    <img onClick={ () => handleShow(5) } src="images/galeria/galeria-6.jpg" className="d-block imagenRedondeadGaleria" alt="Galeria de Mia Canning" data-img-mostrar="6" />
                </div>
                <div className="column">
                    <img onClick={ () => handleShow(6) } src="images/galeria/galeria-7.jpg" className="d-block imagenRedondeadGaleria" alt="Galeria de Mia Canning" data-img-mostrar="7" />
                    <img onClick={ () => handleShow(7) } src="images/galeria/galeria-8.jpg" className="d-block imagenRedondeadGaleria" alt="Galeria de Mia Canning" data-img-mostrar="8" />
                    <img onClick={ () => handleShow(8) } src="images/galeria/galeria-9.jpg" className="d-block imagenRedondeadGaleria" alt="Galeria de Mia Canning" data-img-mostrar="9" />
                </div>
                
            </div>
        </div>
      </div> 
    </Container>
  )
}

export default Galeria