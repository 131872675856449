import React from 'react'
import { Container } from 'react-bootstrap'
import NavbarSite from './NavbarSite'
import IconMia from  '../Resources/mia-headers.svg'
import Triangulo from '../Resources/icono-triangulo.svg'
import Bullet from './Bullet'

const MacroFrancciones = () => {
  return (
    <Container className='p-0 mb-0' >
         <div><h1 className='navbarH1'>DISTRITO</h1></div>
        <NavbarSite/>
        <div className='containerHeaders distritoContainer'>
          <div className='row'>
            <div className='col-md-8 col-lg-9 col-xl-10'>
              <p className='distritoContainer__titulo'>
                <span className='distritoContainer__titulo--bold'>DISTRITO MIA CANNING</span><br />
               <span className='distritoContainer__titulo--light'>LAS MACROFRACCIONES</span>
              </p>
            </div>
            <div className='col-md-4 col-lg-3 col-xl-2 distritoContainer__mia'>
              <img src={IconMia} alt="" className='distritoContainer__mia--img' />
            </div>
          </div>
        </div>
      <div className='containerBackground containerBackground__ubicacion'>
        <div className='row'>

            <div className='col-12 imagenContainer imagenContainer__top text-center'>
                <img src="images/macro-fracciones/macro-fracciones-vista.jpg" className='imagenRedondeada' alt="Render aereo ubicacion de Mia Canning" />
            </div>

            <div className='col-12'>
                <div className='textosBloques'>
                  <p>  <img src={Triangulo} alt="" className='textosBloques__icon' /> </p>
                  <p className='textosBloques__txt'>
                    SUPERFICIE DE LAS MACROFRACCIONES:
                  </p>
                </div>
            </div>

            <div className='col-12 imagenContainer text-center pt-0'>
                <img src="images/macro-fracciones/macrofracciones.jpg" className='imagenRedondeada item__desktop' alt="Render aereo macro fracciones de Mia Canning" />
                <img src="images/macro-fracciones/macrofracciones-mobile.jpg" className='imagenRedondeada item__mobile' alt="Render aereo macro fracciones de Mia Canning" />
            </div>

        </div>
      </div> 
    </Container>
  )
}

export default MacroFrancciones